import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Button from 'src/common/components/button/Button'
import { COLOR_THEME } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import sanitize from 'src/common/utils/js/sanitize'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { indent, background_color, removeBottomPadding } = props

  const background =
    background_color === COLOR_THEME.CRISSY_FIELD
      ? SECONDARY_COLOR.LIGHT[40]
      : theme.palette.presidio.color.LIGHT_BACKGROUND

  const desktopBottomPadding = removeBottomPadding ? rem(0.1) : rem(64)

  return {
    container: {
      background: background,
      padding: `${rem(40)} ${rem(24)} ${
        removeBottomPadding ? rem(0.1) : rem(40)
      }`,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(40)} ${rem(40)} ${
          removeBottomPadding ? rem(0.1) : rem(40)
        }`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(64)} ${
          indent
            ? `${rem(258)} ${desktopBottomPadding} ${rem(120)}`
            : `${rem(258)} ${desktopBottomPadding} ${rem(40)}`
        }`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${
          indent
            ? `${rem(448)} ${desktopBottomPadding} ${rem(252)}`
            : `${rem(544)} ${desktopBottomPadding} ${rem(156)}`
        }`,
      },
    },
    title: {
      marginBottom: rem(40),
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('lg')]: {
        fontSize: rem(24),
      },
    },
    description: {
      ...theme.typography.largeBody.default,
      color: theme.palette.presidio.color.DARK_GRAY,
      '& h4': {
        ...theme.typography.h4,
        display: 'inline',
        margin: 0,
      },
      '& a': {
        ...theme.typography.largeBody.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    button: {
      marginTop: rem(40),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
  }
})

export default function ShortDescription(props) {
  const { data } = props
  if (!data) return null

  const { short_description, section_id } = data
  if (!short_description) return null

  const {
    title,
    short_desc,
    cta_button,
    indent,
    background_color,
    remove_bottom_padding: removeBottomPadding,
  } = short_description
  if (!title && !short_desc) return null

  const { classes } = useStyles({
    indent,
    background_color,
    removeBottomPadding,
  })

  const linkClickHandler = useLinkClickHandler()

  const gaTag = 'wysiwyg'

  return (
    <div
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
    >
      <Typography variant="h3" component="div" className={classes.title}>
        {title}
      </Typography>
      <Typography component="div" className={classes.description}>
        {sanitize(short_desc, gaTag)}
      </Typography>
      {cta_button && (
        <a
          href={cta_button.url}
          onClick={(e) => {
            e.preventDefault()
            linkClickHandler(cta_button)
          }}
          data-ga-location="shortdescription_cta"
        >
          <Button
            className={classes.button}
            variant={cta_button.variant}
            tabIndex={-1}
            endIcon={
              isUrlExternal(cta_button?.url) ? <ButtonExternalLink /> : null
            }
          >
            {cta_button.title || 'Learn more'}
          </Button>
        </a>
      )}
    </div>
  )
}

ShortDescription.propTypes = {
  data: PropTypes.shape({
    short_description: PropTypes.shape({
      title: PropTypes.string,
      short_desc: PropTypes.string,
      cta_button: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
        variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
      }),
      indent: PropTypes.bool,
      background_color: PropTypes.oneOf([
        COLOR_THEME.THE_BAKER_BEACH,
        COLOR_THEME.CRISSY_FIELD,
      ]),
      remove_bottom_padding: PropTypes.bool,
    }).isRequired,
  }),
}
